import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { Button } from '@/components/Button';
import SearchModal from './SearchModal';
import { Search } from '@mui/icons-material';

const StockEntry = ({
  onStockEntryClick = () => {},
  fetchFilingsSummary = () => {},
  defaultSymbol = '',
  basicMode = false,
}) => {
  const [symbol, setSymbol] = useState(defaultSymbol);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const _onClick = () => {
    onStockEntryClick(symbol);
  };

  const _onChange = (e) => {
    setSymbol(e.target.value?.toUpperCase());
  };

  const _onKeyUp = (e) => {
    if (e.keyCode == 13) {
      onStockEntryClick(symbol);
    }
  };

  useEffect(() => {
    setSymbol(defaultSymbol);
  }, [defaultSymbol]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '0.5rem',
        placeItems: 'center',
        margin: '0.5rem',
      }}
    >
      {basicMode ? (
        <>
          <TextField
            label="Symbol"
            size="small"
            inputProps={{
              readOnly: true, // Make it non-editable
            }}
            onChange={_onChange}
            onKeyUp={_onKeyUp}
            value={symbol}
          />
          <Button
            variant="contained"
            sx={{ height: '2.5rem' }}
            onClick={_onClick}
          >
            Go
          </Button>
        </>
      ) : (
        <Box
          sx={{
            border: `1px solid var(--border-divider)`,
            cursor: 'pointer',
            p: '0.5rem 1rem 0.5rem 0.5rem',
            display: 'flex',
            gap: 1,
          }}
          onClick={() => setShowSearchModal(true)}
        >
          <Search /> <Box>{symbol || 'Select symbol'}</Box>
        </Box>
      )}

      <Button
        sx={{ height: '2.5rem' }}
        onClick={() => fetchFilingsSummary(symbol)}
      >
        Filings insights
      </Button>
      <SearchModal
        show={showSearchModal}
        onClose={() => {
          setShowSearchModal(false);
        }}
        onSuggestionClick={onStockEntryClick}
      />
    </Box>
  );
};

export default StockEntry;
