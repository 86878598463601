import { Typography } from '@mui/material';
import { Box } from '@mui/system';

const KeyValueCell = ({
  label,
  value,
  labelPlacement = 'bottom',
  fallbackValue = '-',
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: labelPlacement === 'top' ? 'column-reverse' : 'column',
      }}
    >
      <Typography sx={{ color: 'var(--font-color)' }} variant="h6">
        {value || fallbackValue}
      </Typography>
      <Typography variant="subtitle2">{label}</Typography>
    </Box>
  );
};

export default KeyValueCell;
