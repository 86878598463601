import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { EnhancedTable } from '@/components/Table';
import { stockFieldsConfig } from 'config/fields';
import { numberWithCommas } from '@/lib/utils';
import { useDispatch, useSelector } from 'react-redux';
import { selectStreamTrades } from 'store/streamSlice';
import { CONTEXT_MENU_IDS, TABLE_CONTEXT_MENU } from '@/lib/utils/constants';
import { selectWatchlists, setWatchlists } from 'store/appSlice';
import { updateWatchlist } from '@/lib/watchlists';

const rightClickMenu = [TABLE_CONTEXT_MENU.ADD_TO_WATCHLIST];

const RealTimeGainersAndLosers = ({
  onRowClick = () => {},
  onUpdate = () => {},
}) => {
  const [gainersConfig, setGainersConfig] = useState({ data: [], columns: [] });
  // const trades = useSelector(selectStreamTrades);
  const existingPositions = useRef([]);
  const itemsAlreadyClicked = useRef([]);
  const watchlists = useSelector(selectWatchlists);
  const dispatch = useDispatch();

  const movedUp = (symbol, newIndex) => {
    if (existingPositions.current?.length) {
      const foundIndex = existingPositions.current.indexOf(symbol);

      if (foundIndex === -1) {
        return false;
      }
      if (foundIndex > newIndex) {
        return true;
      }
    }

    return false;
  };

  const movedDown = (symbol, newIndex) => {
    if (existingPositions.current?.length) {
      const foundIndex = existingPositions.current.indexOf(symbol);

      if (foundIndex === -1) {
        return false;
      }
      if (foundIndex < newIndex) {
        return true;
      }
    }

    return false;
  };

  const _onRowClick = (row, index) => {
    itemsAlreadyClicked.current = [...itemsAlreadyClicked.current, row.index];
    onRowClick(row, index);
    setGainersConfig((_config) => ({
      ..._config,
      data: _config.data.map((ele) => ({
        ...ele,
        isNotClicked: !itemsAlreadyClicked.current.includes(ele.index),
      })),
    }));
  };

  const throttledUpdates = useCallback(
    _.throttle((trades) => {
      setGainersConfig((_config) => {
        const updatedData = _config.data
          .map((ele) => {
            const currentPrice = trades[ele.symbol] || ele.CurrentPrice;
            const percentage =
              parseFloat(
                (
                  ((currentPrice - ele.previousClose) / ele.previousClose) *
                  100
                ).toFixed(2)
              ) || ele.Percentage;

            return {
              ...ele,
              CurrentPrice: currentPrice,
              Percentage: percentage,
            };
          })
          .sort((a, b) => b.Percentage - a.Percentage)
          .map((ele, newIndex) => ({
            ...ele,
            movedUp: movedUp(ele.Symbol, newIndex),
            movedDown: movedDown(ele.Symbol, newIndex),
          }));

        return {
          ..._config,
          data: updatedData,
        };
      });
    }, 5000),
    []
  );

  // Handle right click menu item click
  const onMenuItemClick = async ({ id }, rowData) => {
    switch (id) {
      case CONTEXT_MENU_IDS.ADD_TO_WATCHLIST:
        const { symbol } = rowData;
        const [watchlist] = _.cloneDeep(watchlists);
        watchlist.symbols.unshift(symbol);
        await updateWatchlist(watchlist._id, { ...watchlist });
        dispatch(setWatchlists([watchlist, ...(watchlists.slice(1), [])]));
        break;
      default:
    }
  };

  // useEffect(() => {
  //   if (Object.keys(trades).length) {
  //     throttledUpdates(trades);
  //   }
  // }, [JSON.stringify(trades)]);

  useEffect(() => {
    const realTimeGainersAndLosersSSE = new EventSource(
      `/api/real-time/gainers-losers`
    );

    realTimeGainersAndLosersSSE.onmessage = (e) => {
      const streamData = JSON.parse(e.data || '[]');
      setGainersConfig({
        data: streamData?.gainers?.map((ele, newIndex) => {
          return {
            ...ele,
            Symbol: ele.symbol,
            CurrentPrice: ele.currentPrice,
            Volume: numberWithCommas(ele.volume),
            Percentage: ele.percentage,
            movedUp: movedUp(ele.symbol, newIndex),
            movedDown: movedDown(ele.symbol, newIndex),
            PreviousClose: ele.previousClose,
          };
        }),
        columns: [
          stockFieldsConfig.enrichedSymbolWithMovement,
          stockFieldsConfig.Percentage,
          stockFieldsConfig.CurrentPrice,
          stockFieldsConfig.PreviousClose,
          stockFieldsConfig.Volume,
        ],
      });

      existingPositions.current = streamData.gainers.map(
        ({ symbol }) => symbol
      );

      onUpdate(existingPositions.current);
    };

    realTimeGainersAndLosersSSE.onerror = () => {
      // error log here
      realTimeGainersAndLosersSSE.close();
    };

    return () => {
      realTimeGainersAndLosersSSE.close();
    };
  }, []);

  return (
    <EnhancedTable
      heading="Gainers"
      menuItems={rightClickMenu}
      onMenuItemClick={onMenuItemClick}
      config={gainersConfig}
      onRowClick={_onRowClick}
    />
  );
};

export default RealTimeGainersAndLosers;
