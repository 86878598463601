import { updateWatchlist } from '@/lib/watchlists';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { selectWatchlists, setWatchlists } from 'store/appSlice';

const AddToWatchlist = ({ symbol, children = <></> }) => {
  const watchlists = useSelector(selectWatchlists);
  const dispatch = useDispatch();
  const onClick = async (evt) => {
    evt.stopPropagation();

    const [watchlist] = _.cloneDeep(watchlists);
    watchlist.symbols.unshift(symbol);

    await updateWatchlist(watchlist._id, {
      ...watchlist,
    });

    dispatch(setWatchlists([watchlist, ...(watchlists.slice(1) || [])]));
  };

  return (
    <Box sx={{ height: '100%', width: '100%' }} onClick={onClick}>
      {children}
    </Box>
  );
};

export default AddToWatchlist;
