import { Box, Link, Typography } from '@mui/material';
import React from 'react';

import styles from './Fundamentals.module.css';
import ExternalLink from '@/page-components/Common/ExternalLink';
import KeyValueCell from '@/components/KeyValueCell';

const fundamentalsConfig = [
  {
    label: 'Float',
    value: 'Shs Float',
  },
  {
    label: 'Avg Morning Push',
    value: 'avgMorningPush',
  },
  {
    label: 'Avg Range',
    value: 'avgRange',
  },
  {
    label: 'Inst Own',
    value: 'Inst Own',
  },
  {
    label: 'Insider Own',
    value: 'Insider Own',
  },
  {
    label: 'Income',
    value: 'Income',
  },
  {
    label: 'Avg Volume',
    value: 'Avg Volume',
  },
  {
    label: 'Sales',
    value: 'Sales',
  },
];

const Fundamentals = ({ fundamentals = {} }) => {
  return (
    <Box className={styles.container}>
      {fundamentalsConfig.map((f) => {
        return (
          <KeyValueCell
            label={f.label}
            value={fundamentals[f.value]}
            labelPlacement="top"
            key={f.label}
          />
        );
      })}
      <ExternalLink to="https://finviz.com" label="Powered by finviz.com" />
    </Box>
  );
};

export default Fundamentals;
