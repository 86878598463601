import React, { useState, useRef, useEffect } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';

const RightClickContainer = ({ children, menuItems = [], onMenuItemClick }) => {
  const [menuPosition, setMenuPosition] = useState(null);
  const containerRef = useRef(null);

  // Handle right-click
  const handleRightClick = (event) => {
    event.preventDefault();
    setMenuPosition({
      mouseX: event.clientX,
      mouseY: event.clientY,
    });
  };

  // Close menu
  const handleClose = () => setMenuPosition(null);

  // Listen for the Escape key to close the menu
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  // Render
  return (
    <Box
      ref={containerRef}
      onContextMenu={handleRightClick}
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: 'var(--card-bg)',
        borderRadius: 2,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {children}

      <Menu
        open={!!menuPosition}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          menuPosition
            ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
            : undefined
        }
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              onMenuItemClick(item);
              handleClose();
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default RightClickContainer;
