import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Button } from '@/components/Button';
import styles from './Dialog.module.css';
import { Close } from '@mui/icons-material';

const DialogComponent = ({
  onClose = () => {},
  open,
  actions = [],
  title = '',
  maxWidth = 'lg',
  children,
  fullScreen = false,
}) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={styles.modal}
      fullWidth
      fullScreen={fullScreen}
      classes={{ container: fullScreen ? styles.fullScreenContainer : '' }}
      maxWidth={fullScreen ? false : maxWidth}
    >
      <DialogTitle
        className={styles.dialogTitle}
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        className={styles.iconButton}
      >
        <Close />
      </IconButton>
      <DialogContent dividers className={fullScreen ? '' : styles.modalContent}>
        {children}
      </DialogContent>
      {actions?.length ? (
        <DialogActions>
          {actions.map((action) => (
            <Button key={action.label} autoFocus onClick={action.onClick}>
              {action.label}
            </Button>
          ))}
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

export default DialogComponent;
