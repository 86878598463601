import { useEffect, useRef, useState } from 'react';
import { EnhancedTable } from '@/components/Table';
import { stockFieldsConfig } from 'config/fields';
import { playPristine } from '@/lib/utils/play';
import RightClickContainer from '@/components/RightClickMenu';
import { useDispatch, useSelector } from 'react-redux';
import { selectWatchlists, setWatchlists } from 'store/appSlice';
import { updateWatchlist } from '@/lib/watchlists';
import { CONTEXT_MENU_IDS, TABLE_CONTEXT_MENU } from '@/lib/utils/constants';

const rightClickMenu = [TABLE_CONTEXT_MENU.ADD_TO_WATCHLIST];

const RealTimeHalts = ({ onRowClick = () => {} }) => {
  const [config, setConfig] = useState([]);
  const existingIndices = useRef([]);
  const itemsAlreadyClicked = useRef([]);
  const watchlists = useSelector(selectWatchlists);
  const dispatch = useDispatch();

  const _onRowClick = (row, index) => {
    itemsAlreadyClicked.current = [...itemsAlreadyClicked.current, row.index];
    onRowClick(row, index);
    setConfig((_config) => ({
      ..._config,
      data: _config.data.map((ele) => ({
        ...ele,
        isNotClicked: !itemsAlreadyClicked.current.includes(ele.index),
      })),
    }));
  };

  // Handle right click menu item click
  const onMenuItemClick = async ({ id }, rowData) => {
    switch (id) {
      case CONTEXT_MENU_IDS.ADD_TO_WATCHLIST:
        const { symbol } = rowData;
        const [watchlist] = _.cloneDeep(watchlists);
        watchlist.symbols.unshift(symbol);
        await updateWatchlist(watchlist._id, { ...watchlist });
        dispatch(setWatchlists([watchlist, ...(watchlists.slice(1), [])]));
        break;
      default:
    }
  };

  useEffect(() => {
    const realTimeHaltsSSE = new EventSource(`/api/real-time/halts`);

    realTimeHaltsSSE.onmessage = (e) => {
      const newIndices = JSON.parse(e.data || '[]')
        .filter((ele) => ['LUDP', 'M'].includes(ele.reasonCode))
        .map(
          (ele) =>
            `${ele.symbol}-${ele.haltTime}${
              ele.resumptionTime ? '-' + ele.resumptionTime : ''
            }`
        );

      setConfig({
        data: JSON.parse(e.data || '[]')
          .filter((ele) => ['LUDP', 'M'].includes(ele.reasonCode))
          .map((ele) => {
            const index = `${ele.symbol}-${ele.haltTime}${
              ele.resumptionTime ? '-' + ele.resumptionTime : ''
            }`;
            return {
              ...ele,
              Symbol: ele.symbol,
              HaltTime: ele.haltTime,
              ResumptionTime: ele.resumptionTime,
              index: index,
              isNotClicked:
                itemsAlreadyClicked.current?.length &&
                !itemsAlreadyClicked.current.includes(index),
            };
          }),
        columns: [
          stockFieldsConfig.issueSymbol,
          stockFieldsConfig.haltTime,
          stockFieldsConfig.resumptionTime,
          stockFieldsConfig.reasonCode,
        ],
      });

      //Check for new entries
      let newEntriesExist = false;

      newIndices.forEach((idx) => {
        if (newIndices?.length && !existingIndices.current.includes(idx)) {
          newEntriesExist = true;
        }
      });

      existingIndices.current = newIndices;

      if (newEntriesExist) {
        playPristine(Audio);
      }
    };

    realTimeHaltsSSE.onerror = () => {
      // error log here
      realTimeHaltsSSE.close();
    };

    return () => {
      realTimeHaltsSSE.close();
    };
  }, []);

  return (
    <EnhancedTable
      heading="Halts"
      menuItems={rightClickMenu}
      onMenuItemClick={onMenuItemClick}
      config={config}
      onRowClick={_onRowClick}
      highlightItems={false}
    />
  );
};

export default RealTimeHalts;
